import NOOP from 'constants/noop';

import { TActionRequestState, TActionRequestDispatch } from './ActionRequestProvider.types';

export const INITIAL_STATE: TActionRequestState = {
  pendingComplianceRequests: [],
  loading: true,
};

export const INITIAL_DISPATCH: TActionRequestDispatch = {
  startPolling: NOOP,
  stopPolling: NOOP,
};
