import React, { useState } from 'react';

import { CopyIcon } from 'components/Icons';
import Box from 'components/core/Box';
import Text from 'components/core/Text';

import useTimeout from 'hooks/useTimeout';

import { TCopyInfoItemProps } from './CopyInfoItem.types';
import { StyledText, StyledButton } from './CopyInfoItem.styles';

function CopyInfoItem({ titleId, value, valueColor, iconColor, ...rest }: TCopyInfoItemProps) {
  const [copied, setCopy] = useState(false);
  const [startTimeout] = useTimeout({
    delay: 750,
    callback: () => setCopy(false),
    autoStart: false,
  });

  async function handleCopy() {
    try {
      if (value && navigator.clipboard) {
        setCopy(true);
        startTimeout(true);
        await navigator.clipboard.writeText(value);
      }
    } catch (e) {
      // no log
    }
  }

  return (
    <Box paddingY="m" {...rest}>
      <Text id={titleId} color="infoItemTitle" variant="smallTextSemiBold" mb="xs" />
      <StyledButton type="button" onClick={handleCopy}>
        <Box alignment="row.verticalCenter">
          <Box flex={1}>
            <StyledText variant="largeTextSemiBold" color={valueColor}>
              {value}
            </StyledText>
          </Box>
          {copied ? (
            <Text id="label.copied" variant="smallTextSemiBold" color={iconColor} />
          ) : (
            <CopyIcon iconColor={iconColor} />
          )}
        </Box>
      </StyledButton>
    </Box>
  );
}

export default CopyInfoItem;
