const API_ENDPOINT = {
  STATUS: '/status',
  LOGIN: '/v1/login',
  LOGIN_FROM_TWO_FACTOR: '/v1/login-from-two-factor',
  VERIFICATION_CODE_RESEND: '/v1/verification-code/resend',
  VERIFICATION_CODE_REGISTER_CHECK: '/v1/verification-code/register/check',
  REGISTER_CONFIRMATION_MAIL_SEND: '/v1/register/confirmation-email/send',
  REGISTER_CONFIRMATION_MAIL_CHECK: '/v1/register/confirmation-email/check',
  REGISTER_MEMBER_UPDATE: '/v1/register/member-update',
  LOGIN_ATTEMPT_VERIFICATION_CODE_SEND:
    '/v1/login-attempts/{loginAttemptId}/verification-code/send',
  REFRESH_TOKEN: '/v1/refresh',
  MERCHANTS_CREATE_SOLE_TRADER: '/v1/merchants/sole-trader',
  MERCHANTS_CREATE_COMPANY: '/v1/merchants/company',
  MERCHANTS_CREATE: '/v1/merchants',
  DIRECTORS: '/v1/directors',
  DIRECTORS_V2: '/v2/directors',
  DIRECTORS_FROM_MEMBER: '/v1/directors/from-member',
  DIRECTORS_UPDATE: '/v2/directors/{id}',
  SHAREHOLDERS: '/v1/shareholders',
  SHAREHOLDERS_V2: '/v2/shareholders',
  SHAREHOLDERS_UPDATE: '/v2/shareholders/{id}',
  SHAREHOLDERS_FROM_DIRECTOR: '/v1/shareholders/from-director',
  SUBMIT_BUSINESS_OWNERS: '/v1/onboarding/business-owners/submit',
  DOCUMENTS: '/v1/documents',
  ONBOARDING: '/v1/onboarding',
  ONBOARDING_BUSINESS_DETAILS: '/v1/onboarding/business-details',
  ONBOARDING_BUSINESS_DETAILS_OPTIONS: '/v1/onboarding/business-details/{type}/options',
  ONBOARDING_DOCUMENT: '/v1/onboarding/{merchantId}/document',
  ONBOARDING_APPLICATION_SELECT_BILLING_PACKAGE: '/v2/onboarding-sections/billing-packages',
  ONBOARDING_SECTIONS: '/v1/onboarding/sections',
  MERCHANTS: '/v1/merchants',
  MERCHANT_CURRENT: '/v2/merchants/current',
  ONBOARDING_DOCUMENT_TYPES: '/v1/onboarding/documents/types',
  SUBMIT_DOCUMENTS: 'v1/onboarding/documents/submit',
  LIVENESS_CHECK_SDK_TOKEN: '/v1/liveness-check/token',
  LIVENESS_CHECK: '/v1/liveness-check/check',
  RESET_PASSWORD_SEND_EMAIL: '/v1/password/reset/send-email',
  RESET_PASSWORD: '/v1/password/reset',
  CHANGE_PASSWORD: '/v1/password/change',
  CREATE_PASSWORD: '/v1/password/create',
  TEAM_MEMBERS: '/v2/team-members',
  TEAM_MEMBERS_DELETE: '/v1/team-members',
  MEMBERS: '/v1/members',
  MEMBERS_PHONE_NUMBER: '/v1/members/phone-number',
  ADD_TEAM_MEMBER_EMPLOYEE: '/v1/team-members/employee',
  ADD_TEAM_MEMBER_MANAGER: '/v1/team-members/manager',
  ADD_TEAM_MEMBER_DIRECTOR: '/v1/team-members/director',
  TEAM_MEMBER_DETAIL: '/v2/team-members/{id}',
  MEMBERS_ME: '/v2/members/me',
  MEMBERS_COMPANY_ROLE: '/v1/members/company-role',
  BENEFICIARIES: '/v1/beneficiaries',
  BENEFICIARY_BY_ID: '/v1/beneficiaries/{id}',
  BENEFICIARIES_CURRENCIES: '/v1/beneficiaries/currencies',
  BENEFICIARIES_FIELDS: '/v1/beneficiaries/fields',
  BENEFICIARIES_AUTHENTICATION: '/v1/beneficiaries/{id}/authentication-preference',
  SEND_MONEY: '/v2/transactions/send',
  SEND_MONEY_INFO: '/v1/transactions/send/info',
  COUNTRIES: '/v1/countries',
  HOME_ACTIVITIES: '/v1/dashboard/activities',
  TRANSACTIONS: '/v1/transactions',
  TRANSACTION_DETAIL: '/v1/transactions/{id}',
  DASHBOARD_ACTIONS: '/v1/dashboard/actions',
  DASHBOARD_UPCOMING: '/v2/dashboard/upcoming',
  LEDGER_ENTRIES: '/v1/ledger-entries',
  PAYMENT_DETAIL: '/v1/payments/{id}',
  CREATE_TRANSACTION_STATEMENTS: '/v1/statements/transactions',
  CREATE_PAYMENTS_STATEMENTS: '/v1/statements/payments',
  CREATE_ACCOUNT_STATEMENTS: '/v1/statements/ledgers',
  TRANSACTION_STATEMENT: '/v1/statements/transactions/{id}',
  PAYMENT_STATEMENT: '/v1/statements/payments/{id}',
  EMPLOYEE_DASHBOARD_ACTIVITIES: '/v1/dashboard/activities/employee',
  FORCE_UPDATE: '/v1/force-update',
  USER_INVITATIONS: '/v1/invitations',
  COMPLIANCE_REQUESTS: '/v1/compliance-requests',
  COMPLIANCE_REQUESTS_SUBMIT: '/v1/compliance-requests/{id}/submit',
  MERCHANT_BANK_ACCOUNT_INFO: '/v1/merchants/{merchantId}/bank-account-info',
  LEDGERS: '/v1/ledgers',
  LEDGER_DETAIL: '/v1/ledgers/{ledgerId}',
  CHANGE_MAIN_LEDGER: '/v1/ledgers/{ledgerId}/main',
  PAYOUTS: '/v1/payouts',
  PAYOUTS_TOP_UPS: '/v1/payouts/top-ups',
  CARDS: '/v1/cards',
  CARD_COLORS: '/v2/cards/colors/{cardType}',
  CARDS_SECURITY_QUESTIONS: '/v1/cards/security-questions',
  CARD_DETAIL: '/v1/cards/{cardId}',
  CARD_RULES: '/{cardId}/card-rules',
  CARD_SPENDING_LIMIT: '/v1/cards/{cardId}/card-rules/spending-limit',
  CARD_SINGLE_TRANSACTION_LIMIT: '/v1/cards/{cardId}/card-rules/single-transaction-limit',
  CREATE_VIRTUAL_CARD: '/v3/cards/virtual',
  CREATE_PHYSICAL_CARD: '/v2/cards/physical',
  CARD_FREEZE: '/v1/cards/{cardId}/freeze',
  CARD_UNFREEZE: '/v1/cards/{cardId}/unfreeze',
  CARD_TERMINATE: '/v1/cards/{cardId}/close',
  CARD_ACTIVATE: '/v1/cards/{cardId}/activate',
  CARD_PIN: '/v1/cards/{cardId}/pin',
  CARD_PIN_RESET: '/v1/cards/{cardId}/pin/reset',
  CARD_CREATE_TOKEN: '/v1/cards/{cardId}/create-token',
  CARD_FEE_INFO: '/v1/cards/fee/info',
  SCHEDULED_TRANSACTION_ORDERS: '/v1/scheduled-transaction-orders',
  SCHEDULED_TRANSACTION_ORDER_DETAIL: '/v1/scheduled-transaction-orders/{id}',
  SCHEDULED_SINGLE_TRANSACTION_ORDER_DETAIL: '/v1/scheduled-transaction-orders/{id}/single',
  SCHEDULED_BULK_TRANSACTION_ORDER_DETAIL: '/v1/scheduled-transaction-orders/{id}/bulk',
  SCHEDULED_TRANSACTION_ORDER_TRANSFERS: '/v1/scheduled-transaction-orders/{id}/transfers',
  SCHEDULED_TRANSACTION_ORDERS_HISTORY: '/v1/scheduled-transaction-orders/{id}/history',
  BILLING_PACKAGES: '/v3/billing-packages',
  BILLING_PACKAGES_CURRENT: '/v3/billing-packages/current',
  BILLING_PACKAGES_CHANGE: '/v2/billing-packages/change',
  BILLING_PACKAGES_CHANGE_DETAIL: '/v2/billing-packages/{id}/change',
  DEBTS: '/v1/debts',
  TOTAL_DEBTS: '/v1/debts/total',
  TOP_UP_CARD: '/v1/top-up/card',
  TOP_UP_TOKENIZED_CARD: '/v1/top-up/tokenized-cards',
  MERCHANT_FEATURE_FLAGS: '/v1/merchants/feature-flags',
  ACTIVE_CAMPAIGNS_CASHBACK: '/v1/active-campaigns/cashback',
  CASHBACK_CURRENT: '/v1/campaigns/cashback/current',
  CASHBACK_TOTAL: '/v1/campaigns/cashback/total',
  CASHBACK_HISTORY: '/v1/campaigns/cashback/history',
  PAY_LATERS_ELIGIBLE_TRANSACTIONS: '/v1/pay-laters/eligible-transactions',
  PAYMENTS: '/v1/payments',
  PAYMENTS_LEDGERS: '/v1/payments/ledgers',
  FX_RATE: '/v1/transactions/fx-rate',
  FEATURE_FLAGS: '/v2/features',
  OPEN_BANKING_LOGIN: '/v1/open-banking/login',
  OPEN_BANKING_LOGIN_FROM_TWO_FACTOR: '/v1/open-banking/login-from-two-factor',
  OPEN_BANKING_TRANSACTION_SUMMARY:
    '/v1/open-banking/connections/{connectionId}/transactions/summary',
  OPEN_BANKING_TRANSACTION_CONFIRM:
    '/v1/open-banking/connections/{connectionId}/transactions/confirm',
  XERO: '/v1/integrations/xero',
  XERO_SOURCE_ACCOUNTS: '/v1/integrations/xero/source-accounts',
  XERO_SOURCE_ACCOUNTS_MAP: '/v1/integrations/xero/source-accounts/map',
  XERO_SOURCE_ACCOUNTS_SYNC: '/v1/integrations/xero/source-accounts/sync',
  XERO_ELIGIBLE_LEDGERS: '/v1/integrations/xero/eligible-ledgers',
  FX_QUOTES_CREATE: '/v1/fx-quotes',
  FX_QUOTES_UPDATE: '/v1/fx-quotes',
  FX_ORDERS: '/v1/fx-orders',
  BULK_TRANSFERS_DOWNLOAD_TEMPLATE: '/v1/bulk-transfers/download-template',
  BULK_TRANSFERS_UPLOAD_CSV: '/v1/bulk-transfers/upload',
  BULK_TRANSFERS_UPLOADED_FILES: '/v1/bulk-transfers/uploaded-files/{id}',
  BULK_TRANSFERS_SEND_INFO: '/v1/bulk-transfers/send/info',
  BULK_TRANSFERS_SEND: '/v1/bulk-transfers/send',
  BULK_TRANSFER_BATCH_DETAIL: '/v1/bulk-transfers/batches/{bulkTransferBatchId}',
  CAMPAIGN_REFERRALS: '/campaign-api/v1/campaigns/referrals',
  CAMPAIGN_REFERRALS_DETAIL: '/campaign-api/v1/campaigns/referrals/{code}',
  CAMPAIGN_REFERRALS_REWARD: '/campaign-api/v1/campaigns/referrals/{code}/reward',
  CAMPAIGN_REFERRALS_INVITEES: '/campaign-api/v1/campaigns/referrals/{code}/invitees',
  CAMPAIGN_REFERRALS_INVITEES_DETAIL: '/campaign-api/v1/campaigns/referrals/{code}/invitees/{id}',
  ACCOUNTS_RECEIVABLE_INVOICES: '/invoice-api/v1/accounts-receivable-invoices',
  ACCOUNTS_RECEIVABLE_INVOICES_DETAIL: '/invoice-api/v1/accounts-receivable-invoices/{id}',
  ACCOUNTS_RECEIVABLE_INVOICES_DETAIL_CANCEL:
    '/invoice-api/v1/accounts-receivable-invoices/{id}/cancel',
  ACCOUNTS_RECEIVABLE_INVOICES_DETAIL_LINK_PAYMENTS:
    '/invoice-api/v1/accounts-receivable-invoices/{id}/payments',
  ACCOUNTS_RECEIVABLE_INVOICES_SETTINGS: '/invoice-api/v1/accounts-receivable-invoices/settings',
  ACCOUNTS_RECEIVABLE_INVOICES_SETTINGS_MERCHANT_LOGO:
    '/invoice-api/v1/accounts-receivable-invoices/settings/merchant-logo',
  ACCOUNTS_RECEIVABLE_INVOICES_SUMMARY: '/invoice-api/v1/accounts-receivable-invoices/summary',
  ACCOUNTS_RECEIVABLE_INVOICES_DETAIL_CUSTOMERS:
    '/invoice-api/v1/accounts-receivable-invoices/{id}/customers',
  ACCOUNTS_RECEIVABLE_INVOICES_DETAIL_ITEMS:
    '/invoice-api/v1/accounts-receivable-invoices/{id}/items',
  ACCOUNTS_RECEIVABLE_INVOICES_CREATE_FINALIZE:
    '/invoice-api/v1/accounts-receivable-invoices/{id}/finalize',
  ACCOUNTS_RECEIVABLE_INVOICES_SEND_EMAIL:
    '/invoice-api/v1/accounts-receivable-invoices/{id}/send-email',
  ACCOUNTS_RECEIVABLE_INVOICES_TRANSACTIONS:
    '/invoice-api/v1/accounts-receivable-invoices/transactions',
  ACCOUNTS_RECEIVABLE_INVOICES_PREVIEW_PDF: '/invoice-api/v1/accounts-receivable-invoices/{id}/pdf',
  ACCOUNTS_RECEIVABLE_INVOICES_CUSTOMERS: '/invoice-api/v1/customer-templates',
  ACCOUNTS_RECEIVABLE_INVOICES_CUSTOMER_DETAIL: '/invoice-api/v1/customer-templates/{id}',
  ACCOUNTS_RECEIVABLE_INVOICES_ITEMS: '/invoice-api/v1/item-templates',
  ACCOUNTS_RECEIVABLE_INVOICES_ITEM_DETAIL: '/invoice-api/v1/item-templates/{id}',
};

export default API_ENDPOINT;
