import React, { ReactElement, useImperativeHandle, useState } from 'react';
import { Trans } from 'react-i18next';

import PageModal from 'components/PageModal';
import Text from 'components/core/Text';
import Box from 'components/core/Box';
import WamoCard from 'components/WamoCard';

import { useModal } from 'components/core/Modal';
import useTimer from 'hooks/useTimer';
import { getCardHolderName } from 'helpers/getCardHolderTitle';
import { getWamoCardVariantByColorType } from 'enums/CardColorType';

import { TCardViewPinModalProps, TModalState } from './CardViewPinModal.types';

function CardViewPinModal({ modalRef }: TCardViewPinModalProps): ReactElement {
  const ref = useModal();
  const [state, setState] = useState<TModalState>();
  const { time, resetTimer } = useTimer({ defaultSeconds: 0, onCallback: handleCloseModal });

  useImperativeHandle(modalRef, () => ({
    show: (state) => {
      setState(state);
      resetTimer(7);
      ref.current?.show(true);
    },
  }));

  function handleCloseModal() {
    ref.current?.show(false);
    state?.onClose?.();
  }

  return (
    <PageModal
      headerTitleId="placeholder.empty"
      modalRef={ref}
      sideLayoutVisible={false}
      afterClose={state?.onClose}
      shouldHideSideLayoutOnVisibilityChange={false}>
      {state?.card && (
        <Box display="flex" flexDirection="column">
          <Box marginBottom="m" width="100%" maxWidth={320} marginX="auto">
            <WamoCard
              cardType={state.card.type}
              nameOnCard={state.card.nameOnCard ?? getCardHolderName(state.card.cardHolder)}
              truncatedPan={state.card.truncatedPan}
              variant={getWamoCardVariantByColorType(state.card.color)}
              size="default"
              isCardTypeBadgeVisible
            />
          </Box>
          <Text id="label.cardPin" mt="xxl" variant="largeTextRegular" mb="s" textAlign="center" />
          <Box style={{ marginBottom: '5rem' }}>
            <Text variant="smallTitleBold" mb="s" textAlign="center">
              {state.pin}
            </Text>
          </Box>
          <Text as="span" variant="smallTextRegular" textAlign="center">
            <Trans i18nKey="screens.cardViewPin.redirectLabel" values={{ seconds: time.time }}>
              <Text as="span" variant="smallTextBold">
                seconds
              </Text>
            </Trans>
          </Text>
        </Box>
      )}
    </PageModal>
  );
}

export default CardViewPinModal;
