import React, { ReactElement, useImperativeHandle, useState } from 'react';
import masks from '@wamoio/common/lib/masks';

import PageModal from 'components/PageModal';
import WamoCard from 'components/WamoCard';
import Box from 'components/core/Box';
import Card from 'components/core/Card';
import InfoItem from 'components/core/InfoItem';
import CopyInfoItem from 'components/core/CopyInfoItem';

import { useModal } from 'components/core/Modal';
import { getWamoCardVariantByColorType } from 'enums/CardColorType';
import { getCardHolderName } from 'helpers/getCardHolderTitle';

import { TCardInfoModalProps, TModalState } from './CardInfoModal.types';

function CardInfoModal({ modalRef }: TCardInfoModalProps): ReactElement {
  const ref = useModal();
  const [state, setState] = useState<TModalState>();

  useImperativeHandle(modalRef, () => ({
    show: (state) => {
      setState(state);
      ref.current?.show(true);
    },
  }));

  return (
    <PageModal
      headerTitleId="placeholder.empty"
      modalRef={ref}
      sideLayoutVisible={false}
      afterClose={state?.onClose}
      shouldHideSideLayoutOnVisibilityChange={false}>
      {state?.cardInfo && (
        <>
          <Box marginBottom="m" width="100%" maxWidth={320} marginX="auto">
            <WamoCard
              cardType={state.card.type}
              nameOnCard={state.card.nameOnCard ?? getCardHolderName(state.card.cardHolder)}
              truncatedPan={state.card.truncatedPan}
              variant={getWamoCardVariantByColorType(state.card.color)}
              size="default"
              isCardTypeBadgeVisible
            />
          </Box>
          <Card paddingX="m" py="s" marginY="m" width="100%">
            <CopyInfoItem
              titleId="screens.cardInfo.cardNo"
              value={masks.cardNumber.mask(state.cardInfo.pan)}
              valueColor="primary"
            />
            <Box alignment="row.horizontalCenter">
              <InfoItem
                flex={1}
                titleId="screens.cardInfo.cvv"
                value={state.cardInfo.cvv}
                valueColor="primary"
              />
              <InfoItem
                flex={1}
                titleId="screens.cardInfo.expiryDate"
                value={state.cardInfo.expiry}
                valueColor="primary"
              />
            </Box>
          </Card>
        </>
      )}
    </PageModal>
  );
}

export default CardInfoModal;
