import React, { ReactElement, Ref, useImperativeHandle, useState, forwardRef } from 'react';

import Modal, { useModal } from 'components/core/Modal';

import {
  TPopconfirmModalRef,
  TPopconfirmModalState,
  TShowPopconfirmModalProps,
} from './PopconfirmModal.types';
import PopconfirmModalContent from './views/PopconfirmModalContent';

function PopconfirmModal(props: unknown, ref: Ref<TPopconfirmModalRef>): ReactElement {
  const modalRef = useModal();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalState, setModalState] = useState<TPopconfirmModalState | undefined>();

  useImperativeHandle(ref, () => ({
    show: showModal,
    hide: hideModal,
  }));

  function handleClearState() {
    setModalState(undefined);
  }

  function showModal(props: TShowPopconfirmModalProps) {
    setModalState(props);
    modalRef.current?.show(true);
  }

  function hideModal() {
    modalRef.current?.show(false);
  }

  async function handleConfirm() {
    setConfirmLoading(true);
    await modalState?.onConfirm?.();
    setConfirmLoading(false);
    hideModal();
  }

  return (
    <Modal alertModal ref={modalRef} afterClose={handleClearState} hideCloseButton>
      {modalState && (
        <PopconfirmModalContent
          titleId={modalState.titleId}
          titleValues={modalState.titleValues}
          titleProps={modalState.titleProps}
          contentBoxProps={modalState.contentBoxProps}
          descriptionId={modalState.descriptionId}
          descriptionValues={modalState.descriptionValues}
          contentIcon={modalState.contentIcon}
          onCancel={modalState?.onCancel ?? hideModal}
          onConfirm={handleConfirm}
          extraContent={modalState.extraContent}
          cancelButtonTitleId={modalState.cancelButtonTitleId}
          confirmProps={{ ...modalState.confirmButtonProps, loading: confirmLoading }}
          cancelButtonVisible={modalState.cancelButtonVisible}
        />
      )}
    </Modal>
  );
}

export default forwardRef(PopconfirmModal);
