import React, { useImperativeHandle, useRef, useState } from 'react';

import PageModal from 'components/PageModal';
import PasswordForm from 'components/Form/PasswordForm';
import Box from 'components/core/Box';
import CardInfoModal from 'modals/CardInfoModal';
import CardItem from 'components/v2/CardItem';

import { useModal } from 'components/core/Modal';
import {
  TPasswordFormData,
  TPasswordFormRef,
} from 'components/Form/PasswordForm/PasswordForm.types';
import useRetrieveSecureCardDetails from 'hooks/useRetrieveSecureCardDetails';
import { TCardInfoModalRef } from 'modals/CardInfoModal/CardInfoModal.types';

import {
  TCardInfoAuthenticationModalProps,
  TModalState,
} from './CardInfoAuthenticationModal.types';

function CardInfoAuthenticationModal({ modalRef }: TCardInfoAuthenticationModalProps) {
  const ref = useModal();
  const cardInfoModalRef = useRef<TCardInfoModalRef>(null);
  const [state, setState] = useState<TModalState>();
  const formRef = useRef<TPasswordFormRef>(null);

  const { handleRetrieveCardInfo, loading: retrieveSecureCardDetailsLoading } =
    useRetrieveSecureCardDetails(state?.card?.id as string);

  useImperativeHandle(modalRef, () => ({
    show: (state) => {
      setState(state);
      ref.current?.show(true);
    },
  }));

  async function handleCardInfoFlow({ password }: TPasswordFormData) {
    const result = await handleRetrieveCardInfo({
      password,
      onError: () => formRef.current?.clear(),
    });

    if (result?.pan && state?.card) {
      cardInfoModalRef.current?.show({
        card: state.card,
        cardInfo: {
          nameOnCard: state.card.nameOnCard,
          pan: result.pan,
          cvv: result.cvv,
          expiry: state.card.expiryDate,
        },
        onClose: () => ref.current?.show(false),
      });
    }
    return formRef.current?.clear();
  }

  return (
    <>
      <PageModal
        modalRef={ref}
        headerTitleId="screens.headerTitle.cardInfoAuthentication"
        sideLayoutVisible={false}
        shouldHideSideLayoutOnVisibilityChange={false}>
        {state?.card && (
          <>
            <PasswordForm
              ref={formRef}
              onSubmit={handleCardInfoFlow}
              descriptionId="screens.cardInfoAuthentication.description"
              loading={retrieveSecureCardDetailsLoading}>
              <Box mb="l">
                <CardItem card={state.card} />
              </Box>
            </PasswordForm>
          </>
        )}
      </PageModal>
      <CardInfoModal modalRef={cardInfoModalRef} />
    </>
  );
}

export default CardInfoAuthenticationModal;
