import React from 'react';

import Text from 'components/core/Text';
import ListCardButton from 'components/ListCardButton';
import { LockUnlockedIcon, PinCodeIcon } from 'components/Icons';
import Card from 'components/core/Card';

import { isCardFreezing, isCardFrozen } from 'enums/CardStatus';
import { isPhysicalCard } from 'enums/CardType';

import { TSecuritySectionProps } from '../CardDetailSettingsModal.types';
import { UNBLOCK_PIN_CVV_LABEL_MAP } from '../CardDetailSettingsModal.constants';

function CardSecuritySection({ card, onClickShowPin, onClickUnblockPin }: TSecuritySectionProps) {
  const isFrozen = isCardFrozen(card.status) || isCardFreezing(card.status);
  const shouldShowPin = !isFrozen && isPhysicalCard(card.type);
  const shouldShowUnblockPin = !isFrozen;
  const unblockPinLabels = isPhysicalCard(card.type)
    ? UNBLOCK_PIN_CVV_LABEL_MAP.physical
    : UNBLOCK_PIN_CVV_LABEL_MAP.virtual;

  if (!shouldShowPin && !shouldShowUnblockPin) {
    return <></>;
  }

  return (
    <>
      <Text id="label.security" variant="mediumTextBold" mb="s" />
      <Card mb="l">
        {shouldShowPin && (
          <ListCardButton
            titleId="screens.cardDetail.showPin.title"
            titleProps={{ variant: 'mediumTextBold', mb: 'none' }}
            leftComponent={<PinCodeIcon width={32} height={32} iconColor="primary" />}
            onClick={onClickShowPin}
            mb="none"
          />
        )}
        {shouldShowUnblockPin && (
          <ListCardButton
            {...unblockPinLabels}
            titleProps={{ variant: 'mediumTextBold' }}
            leftComponent={<LockUnlockedIcon width={32} height={32} iconColor="primary" />}
            onClick={onClickUnblockPin}
            mb="none"
          />
        )}
      </Card>
    </>
  );
}

export default CardSecuritySection;
