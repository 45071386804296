/* eslint-disable @typescript-eslint/ban-ts-comment */
import i18next from 'i18next';

import {
  isValidPhoneNumber,
  isValidMaxAge,
  isValidMinAge,
  isValidBirthdate,
  isValidCardNumberByLuhn,
  isValidExpiryDate,
} from '@wamoio/common/lib/validations';
import { addMethod, mixed, number, string, StringSchema, array, object } from 'yup';
import { last } from 'lodash/fp';

import { TCountry } from 'types/common';
import {
  ONLY_LATIN_CHARS,
  CARD_DELIVERY_CHARS,
  UK_POST_CODE,
  GENERIC_POSTCODE_PATTERN,
  SPACE,
  ADDRESS_LINE,
  TOWN_OR_CITY,
  NAME,
  SEND_MONEY_DESCRIPTION_COMMON,
  NOT_ALPHANUMERIC,
  BENEFICIARY_NAME,
  DASH,
} from 'constants/regex';
import { UK_COUNTRY_CODE } from 'constants/form';
import { MASKS } from 'helpers/masks/masks.constants';
import BeneficiaryType from 'enums/BeneficiaryType';
import { TRecipientTypeOptions } from 'components/RecipientForm/RecipientForm.types';
import sendMoney from 'helpers/sendMoney';

handleAddYupMethod('name', name);
handleAddYupMethod('nameOnCard', nameOnCard);
handleAddYupMethod('cardDeliveryAddress', cardDeliveryAddress);
handleAddYupMethod('birthdate', isValidBirthdate);
handleAddYupMethod('minAge', isValidMinAge);
handleAddYupMethod('maxAge', isValidMaxAge);
handleAddYupMethod('creditCard', isValidCardNumberByLuhn);
handleAddYupMethod('expiryDate', isValidExpiryDate);
handleAddYupMethod('dateValidation', isValidDate);
handleAddYupMethod('companyName', isValidCompanyName);
handleAddYupMethod('beneficiaryName', isValidBeneficiaryName);
handleAddYupMethod('addressLine', addressLine);
handleAddYupMethod('townOrCity', townOrCity);
handleAddYupMethod('ukPostCode', ukPostCode);
handleAddYupMethod('genericPostCode', genericPostCode);
handleAddYupMethod('minCompanyNameWordCount', (value) =>
  minWordCount(MIN_COMPANY_NAME_WORD_COUNT, value)
);
handleAddYupMethod('sendMoneyDescriptionMin', sendMoneyDescriptionMin);
handleAddYupMethod('sendMoneyDescriptionPattern', sendMoneyDescriptionPattern);

function handleAddYupMethod(methodName: string, validator: (value: string) => boolean) {
  addMethod<StringSchema>(string, methodName, function test(this, message) {
    return this.test(methodName, message, (value) => validator(value as string));
  });
}

const NAME_LENGTH = 50;
const LEDGER_MIN_LENGTH = 3;
const LEDGER_MAX_LENGTH = 50;
const EMAIL_LENGTH = 80;
const BUSINESS_DESCRIPTION_MIN_LENGTH = 25;
const MIN_COMPANY_NAME_WORD_COUNT = 2;
const VALID_COMPANY_SUFFIX = ['LTD', 'LIMITED'];
const SEND_MONEY_DESCRIPTION_MIN_LENGTH = 6;

function isValidCompanyName(value?: string): boolean {
  if (!value) {
    return false;
  }
  const lastWord = last(value.split(' '));
  return !!lastWord && VALID_COMPANY_SUFFIX.includes(lastWord);
}

function minWordCount(wordCount: number, value?: string) {
  if (!value) {
    return false;
  }
  return value.split(' ').length >= wordCount;
}

function ukPostCode(value: string) {
  return UK_POST_CODE.test(value);
}

function addressLine(value: string) {
  return ADDRESS_LINE.test(value);
}

function townOrCity(value: string) {
  return TOWN_OR_CITY.test(value);
}

function genericPostCode(value: string) {
  return GENERIC_POSTCODE_PATTERN.test(value);
}

function name(value: string) {
  return NAME.test(value);
}

function sendMoneyDescriptionMin(value?: string) {
  if (!value) {
    return true;
  }
  const isAllCharactersSame = value.split('').every((char) => char === value[0]);

  const normalizedValue = value.replace(NOT_ALPHANUMERIC, '');
  const hasEnoughAlphanumericCharacter =
    normalizedValue.length >= SEND_MONEY_DESCRIPTION_MIN_LENGTH;

  return !isAllCharactersSame && hasEnoughAlphanumericCharacter;
}

function sendMoneyDescriptionPattern(value?: string) {
  if (!value) {
    return true;
  }

  return SEND_MONEY_DESCRIPTION_COMMON.test(value);
}

function isValidBeneficiaryName(value?: string) {
  if (!value) {
    return false;
  }
  return BENEFICIARY_NAME.test(value);
}

export const REQUIRED_VALIDATION = string().trim().required('validation.required');

export const REQUIRED_OBJECT_VALIDATION = object().required('validation.required');

export const NUMBER_REQUIRED_VALIDATION = number().required('validation.required');

export const NOT_REQUIRED_VALIDATION = string().notRequired();

export const SORT_CODE_VALIDATION = string()
  .required('validation.required')
  .transform((value) => value?.replace(SPACE, '').replace(DASH, ''))
  .min(6, 'validation.general')
  .max(6, 'validation.general');

export const EMAIL_VALIDATION = string()
  .trim()
  .required('validation.email.required')
  .max(EMAIL_LENGTH, 'validation.email.maxLength')
  .email('validation.email');

export const BIRTHDATE_VALIDATION = string()
  .default('')
  .required('validation.required')
  .birthdate('validation.birthdate.invalid')
  .minAge('validation.birthdate.minAge')
  .maxAge('validation.birthdate.maxAge');

export const PASSWORD_VALIDATION = string()
  .trim()
  .required('validation.password.required')
  .length(6, 'validation.newPassword.length');

export const LOGIN_PASSWORD_VALIDATION = string().trim().required('validation.password.required');

export const FILE_VALIDATION = mixed().required('validation.file.required');

export const SMS_CODE_VALIDATION = string()
  .required('validation.required')
  .length(6, 'validation.smsCode.length');

export const CODE_VALIDATION = string()
  .required('validation.code.required')
  .length(6, 'validation.code.length');

function nameOnCard(value: string) {
  return ONLY_LATIN_CHARS.test(value);
}

function cardDeliveryAddress(value: string) {
  return CARD_DELIVERY_CHARS.test(value);
}

function isValidDate(value: string) {
  return !!value && value.length === MASKS.birthdate.length;
}

export const CARD_NAME_VALIDATION = string()
  .required('validation.required')
  .min(5, 'validation.cardName.invalidLength')
  .max(19, 'validation.cardName.invalidMaxLength')
  .nameOnCard('validation.cardName.invalidChars');

export const PHONE_NUMBER_VALIDATION = string()
  .required('validation.required')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .when('phoneNumberCountry', (phoneNumberCountry: TCountry, fieldScheme: any) =>
    fieldScheme.test('phoneNumber', 'validation.phoneNumber', (value: string) =>
      isValidPhoneNumber(`+${phoneNumberCountry.callingCode}${value}`)
    )
  );

export const REGISTRATION_NUMBER_VALIDATION = string()
  .required('validation.required')
  .min(5, 'validation.registrationNumber.invalidLength.min')
  .max(40, 'validation.registrationNumber.invalidLength.max');

export const REGISTRATION_NAME_VALIDATION = string()
  .required('validation.required')
  .max(100, 'validation.registrationName.invalidLength.max');

export const COUNTRY_VALIDATION = object().shape({
  name: string(),
  code: string().required('validation.countryCode.required'),
  callingCode: string(),
});

export const PHONE_COUNTRY_VALIDATION = object().shape({
  name: string(),
  code: string(),
  callingCode: string().required('validation.countryCode.required'),
});

export const ONBOARDING_BUSINESS_OWNER_DOCUMENT_VALIDATION = object().shape({
  label: string(),
  value: string().required('validation.required'),
});

export const CURRENCY_VALIDATION = object().shape({
  code: string(),
  country: string(),
  name: string(),
  symbol: string(),
});

export const CARD_HOLDER_NAME_VALIDATION = string()
  .default('')
  .required('validation.cardHolderName.required')
  .test(
    'isValidNameAndSurname',
    'validation.cardHolderName.surname',
    (value) => !!value && value.split(' ').length > 1
  );

export const CARD_NUMBER_VALIDATION = string()
  .default('')
  .required('validation.cardNumber')
  // @ts-ignore
  .creditCard('validation.cardNumber');

export const CARD_EXPIRY_VALIDATION = string()
  .default('')
  .required('validation.expiryDate')
  // @ts-ignore
  .expiryDate('validation.expiryDate');

export const CARD_CVV_VALIDATION = string().required('validation.cvv');

export const FIRST_NAME_VALIDATION = REQUIRED_VALIDATION
  // @ts-ignore
  .name('validation.name.invalidChars')
  .max(NAME_LENGTH, 'validation.firstName.length');

export const LAST_NAME_VALIDATION = REQUIRED_VALIDATION
  // @ts-ignore
  .name('validation.name.invalidChars')
  .max(NAME_LENGTH, 'validation.lastName.length');

export const LEDGER_NAME_VALIDATION = string()
  .trim()
  .required('validation.required')
  .min(LEDGER_MIN_LENGTH, 'validation.ledger.minLength')
  .max(LEDGER_MAX_LENGTH, 'validation.ledger.maxLength');

export const REQUIRED_DATE_OF_INCORPORATION_VALIDATION = string()
  .required('validation.required')
  // @ts-ignore
  .dateValidation('validation.date');

export const ONBOARDING_BUSINESS_DESCRIPTION_VALIDATION = string()
  .trim()
  .required('validation.required')
  .min(BUSINESS_DESCRIPTION_MIN_LENGTH, 'validation.onboarding.businessDetail.description.min');

export const ONBOARDING_BUSINESS_USING_REASON_VALIDATION = array()
  .min(1, 'validation.onboardingBusinessDetails.usingReason.min')
  .required('validation.onboardingBusinessDetails.usingReason.min');

export const SHARE_PERCENTAGE_VALIDATION = number()
  .transform((currentValue) => (currentValue ? Number(currentValue) : undefined))
  .required('validation.required')
  .max(100, 'validation.sharePercentage.max');

export const POST_CODE_VALIDATION = string().when('country', {
  is: (country: TCountry) => country.code === UK_COUNTRY_CODE,
  // @ts-ignore
  then: REQUIRED_VALIDATION.ukPostCode('validation.postCode'),
  // @ts-ignore
  otherwise: REQUIRED_VALIDATION.genericPostCode('validation.postCode'),
});

export const BENEFICIARY_COMPANY_NAME = string().when('beneficiaryType', {
  is: (beneficiaryType: TRecipientTypeOptions) => beneficiaryType.value === BeneficiaryType.COMPANY,
  then: REQUIRED_VALIDATION.max(100, 'validation.beneficiary.companyName.maxLength')
    // @ts-ignore
    .beneficiaryName('validation.beneficiary.name'),
  otherwise: NOT_REQUIRED_VALIDATION,
});

export const BENEFICIARY_FIRST_NAME = string().when('beneficiaryType', {
  is: (beneficiaryType: TRecipientTypeOptions) => beneficiaryType.value === BeneficiaryType.PERSON,
  then: REQUIRED_VALIDATION.max(50, 'validation.beneficiary.firstName.maxLength')
    // @ts-ignore
    .beneficiaryName('validation.beneficiary.name'),
  otherwise: NOT_REQUIRED_VALIDATION,
});

export const BENEFICIARY_LAST_NAME = string().when('beneficiaryType', {
  is: (beneficiaryType: TRecipientTypeOptions) => beneficiaryType.value === BeneficiaryType.PERSON,
  then: REQUIRED_VALIDATION.max(50, 'validation.beneficiary.lastName.maxLength')
    // @ts-ignore
    .beneficiaryName('validation.beneficiary.name'),
  otherwise: NOT_REQUIRED_VALIDATION,
});

export const MINIMUM_AMOUNT_VALIDATION = (min: number) =>
  string().test((value, ctx) => {
    if (value && Number(value) >= min) {
      return true;
    }
    return ctx.createError({ message: 'validation.minimumAmount' });
  });

// @ts-ignore
export const ADDRESS_LINE_VALIDATION = REQUIRED_VALIDATION.addressLine(
  'validation.addressLine'
).max(50, 'validation.addressLine.maxLength');

// @ts-ignore
export const TOWN_OR_CITY_VALIDATION = REQUIRED_VALIDATION.townOrCity('validation.townOrCity').max(
  50,
  'validation.townOrCity.maxLength'
);

// @ts-ignore
export const CARD_SHIPPING_TOWN_OR_CITY_VALIDATION = REQUIRED_VALIDATION.townOrCity(
  'validation.townOrCity'
).max(20, 'validation.cardShippingTownOrCity.maxLength.20');

export const CARD_SHIPPING_POST_CODE_VALIDATION = string()
  .when('country', {
    is: (country: TCountry) => country.code === UK_COUNTRY_CODE,
    // @ts-ignore
    then: REQUIRED_VALIDATION.ukPostCode('validation.postCode'),
    // @ts-ignore
    otherwise: REQUIRED_VALIDATION.genericPostCode('validation.postCode'),
  })
  .max(10, 'validation.cardShippingPostCode.maxLength.10');

export const CARD_SECURITY_QUESTION_ANSWER = REQUIRED_VALIDATION.min(
  1,
  'validation.securityQuestionAnswer.minLength'
).max(45, 'validation.securityQuestionAnswer.maxLength');

export const CARD_LABEL_VALIDATION = REQUIRED_VALIDATION.max(100, 'validation.cardLabel.maxLength');

// @ts-ignore
export const NAME_ON_CARD_VALIDATION = REQUIRED_VALIDATION.name('validation.name.invalidChars').max(
  20,
  'validation.nameOnCard.maxLength'
);

// @ts-ignore
export const CARD_FIRST_NAME_VALIDATION = REQUIRED_VALIDATION.name(
  'validation.name.invalidChars'
).max(20, 'validation.cardFirstName.maxLength');

// @ts-ignore
export const CARD_LAST_NAME_VALIDATION = REQUIRED_VALIDATION.name(
  'validation.name.invalidChars'
).max(20, 'validation.cardLastName.maxLength');

export const SEND_MONEY_DESCRIPTION = string()
  .nullable()
  .transform((currentValue, originalValue) => (currentValue === '' ? null : originalValue))
  // @ts-ignore
  .sendMoneyDescriptionPattern('validation.sendMoneyDescription.invalid')
  .sendMoneyDescriptionMin('validation.sendMoneyDescription.minLength')
  .test(
    'max-length',
    undefined,
    function testValidation(value: string, { options, createError }: unknown) {
      if (!value) {
        return true;
      }
      const { senderCurrencyCode, receiverCurrencyCode } = options.context;
      const maxLengthInfo = sendMoney.getMaxLengthInfo(senderCurrencyCode, receiverCurrencyCode);

      if (value.length > maxLengthInfo.maxLength) {
        return createError({ message: maxLengthInfo.validationMessage });
      }

      return true;
    }
  );

export const TAX_IDENTIFICATION_NUMBER_VALIDATION = string().max(15, 'validation.maxLength.15');

export const NEXT_INVOICE_NUMBER_VALIDATION = number()
  .transform((currentValue) => (currentValue ? Number(currentValue) : undefined))
  .required('validation.required')
  // @ts-ignore
  .test('max-length', undefined, function test(value: string, { options, createError }: any) {
    if (!value) {
      return true;
    }
    const { nextInvoiceNumber } = options.context;

    if (!!nextInvoiceNumber && Number(nextInvoiceNumber) > Number(value)) {
      return createError({
        message: i18next.t('validation.nextInvoiceNumber.withNumber', {
          number: nextInvoiceNumber,
        }),
      });
    }
    return true;
  });

export const PHONE_NUMBER_V2_VALIDATION = REQUIRED_OBJECT_VALIDATION.shape({
  country: COUNTRY_VALIDATION,
  nationalNumber: REQUIRED_VALIDATION.when('country', (country: TCountry, fieldScheme: any) =>
    fieldScheme.test('phoneNumber', 'validation.phoneNumber.invalid', (value: string) =>
      isValidPhoneNumber(`+${country.callingCode}${value}`)
    )
  ),
});

export const INVOICE_TOWN_OR_CITY_VALIDATION = REQUIRED_VALIDATION
  // @ts-ignore
  .townOrCity('validation.townOrCity');

// @ts-ignore
export const INVOICE_ADDRESS_LINE_VALIDATION = REQUIRED_VALIDATION.addressLine(
  'validation.addressLine.invalid'
);
