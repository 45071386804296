import React, { ReactElement } from 'react';

import Box from 'components/core/Box';
import Text from 'components/core/Text';
import Button from 'components/core/Button';

import { TPopconfirmModalContentProps } from '../PopconfirmModal.types';

function PopconfirmModalContent({
  titleId,
  titleValues,
  descriptionId,
  descriptionValues,
  contentIcon,
  onCancel,
  cancelButtonTitleId = 'button.cancel',
  onConfirm,
  confirmProps,
  extraContent,
  cancelButtonVisible = true,
  titleProps,
  contentBoxProps,
}: TPopconfirmModalContentProps): ReactElement {
  return (
    <Box {...contentBoxProps}>
      {contentIcon && (
        <Box alignment="center" margin="m">
          {contentIcon}
        </Box>
      )}
      <Text
        variant="smallTitleSemiBold"
        id={titleId}
        values={titleValues}
        textAlign="center"
        {...titleProps}
      />
      {descriptionId && (
        <Text
          marginTop="s"
          color="textSecondary"
          id={descriptionId}
          values={descriptionValues}
          textAlign="center"
        />
      )}
      {extraContent}
      <Box marginTop="xl">
        {cancelButtonVisible && (
          <Button variant="secondary" id={cancelButtonTitleId} onClick={onCancel} />
        )}
        <Button
          id={confirmProps?.id ?? 'button.confirm'}
          onClick={onConfirm}
          disabled={confirmProps.loading}
          marginTop="m"
          {...confirmProps}
        />
      </Box>
    </Box>
  );
}

export default PopconfirmModalContent;
