import NOOP from 'constants/noop';

import { TCardCreateDispatch, TCardCreateState } from './CardCreateProvider.types';

export const INITIAL_STATE: TCardCreateState = {
  cardType: undefined,
  cardColor: undefined,
  cardOwner: undefined,
  ledger: undefined,
  cardLabel: undefined,
  cardInfo: undefined,
  nameOnCard: undefined,
  securityQuestion: undefined,
  deliveryAddress: undefined,
  fee: undefined,
};

export const INITIAL_DISPATCH: TCardCreateDispatch = {
  setCardType: NOOP,
  setCardColor: NOOP,
  setCardOwner: NOOP,
  setLedger: NOOP,
  setCardInfo: NOOP,
  setCardLabel: NOOP,
  setNameOnCard: NOOP,
  setDeliveryAddress: NOOP,
  setSecurityQuestion: NOOP,
  setFee: NOOP,
  clear: NOOP,
};
