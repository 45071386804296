import NOOP, { ASYNC_NOOP } from 'constants/noop';

import { TLedgersState, TLedgersDispatch } from './LedgersProvider.types';

export const INITIAL_STATE: TLedgersState = {
  loading: false,
  ledgers: [],
  selectedLedger: undefined,
};

export const INITIAL_DISPATCH: TLedgersDispatch = {
  setLedgers: NOOP,
  findMainLedger: NOOP,
  setSelectedLedger: NOOP,
  requestGetLedgers: ASYNC_NOOP,
  getInitialSelectedLedger: NOOP,
} as unknown as TLedgersDispatch;
