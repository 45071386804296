import { useState } from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';

import { TCommonResponse } from 'types/common';
import { TCardCreateTokenResponse } from 'types/card';
import replaceUrlParams from 'helpers/replaceUrlParams';
import API_ENDPOINT from 'constants/apiEndpoint';
import { showErrorAlert } from 'helpers/showAlertModal';

import {
  THandleRetrieveCardInfoParams,
  THandleRetrieveSecureCardDetailsResult,
} from './useRetrieveSecureCardDetails.types';

function useRetrieveSecureCardDetails(cardId: string) {
  const [loading, setLoading] = useState(false);
  const { post: requestCardCreateToken } = useFetch<TCommonResponse<TCardCreateTokenResponse>>(
    replaceUrlParams(API_ENDPOINT.CARD_CREATE_TOKEN, {
      cardId,
    })
  );

  async function handleRetrieveCardInfo({
    password,
    onError,
  }: THandleRetrieveCardInfoParams): Promise<THandleRetrieveSecureCardDetailsResult | undefined> {
    try {
      setLoading(true);
      const { errorMessage, result } = await requestCardCreateToken({
        password,
      });

      if (errorMessage) {
        onError?.();
        showErrorAlert({
          title: errorMessage,
        });
        return undefined;
      }

      if (result) {
        const secureCardDetails = await handleRetrieveSecureCardDetails(
          result.secureCardDetailsUrl,
          result.token
        );
        return secureCardDetails;
      }
      return undefined;
    } catch (e) {
      Sentry.captureException(e);
      showErrorAlert({ titleId: 'label.unexpectedError' });
      return undefined;
    } finally {
      setLoading(false);
    }
  }

  async function handleRetrieveSecureCardDetails(
    apiUrl: string,
    token: string
  ): Promise<THandleRetrieveSecureCardDetailsResult | undefined> {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const responseJson = await response.json();

      return {
        pan: responseJson.pan,
        cvv: responseJson.cvv2,
        pin: responseJson.pin,
      };
    }
    return undefined;
  }

  return {
    loading,
    handleRetrieveCardInfo,
  };
}

export default useRetrieveSecureCardDetails;
