import React, { createContext, ReactElement, useContext, useState } from 'react';

import { TChildrenOnly } from 'types/common';
import { TTeamMember } from 'types/teamMember';
import { TLedger } from 'types/ledger';
import cardType from 'enums/CardType';
import CardColorType from 'enums/CardColorType';
import { TFee } from 'components/v2/core/AmountInput/AmountInput.types';

import {
  TCardCreateContext,
  TCardCreateState,
  TCardSecurityQuestionResponse,
  TNewCardInfo,
  TNewPhysicalCardDeliveryAddress,
} from './CardCreateProvider.types';
import { INITIAL_DISPATCH, INITIAL_STATE } from './CardCreateProvider.constants';

const cardCreateContext = createContext<TCardCreateContext>({
  cardCreateState: INITIAL_STATE,
  cardCreateDispatch: INITIAL_DISPATCH,
});

function CardCreateProvider({ children }: TChildrenOnly): ReactElement {
  const [state, setState] = useState<TCardCreateState>(INITIAL_STATE);

  function setCardType(cardType: cardType) {
    setState((prevState) => ({
      ...prevState,
      cardType,
    }));
  }

  function setCardOwner(cardOwner: TTeamMember) {
    setState((prevState) => ({
      ...prevState,
      cardOwner,
    }));
  }

  function setLedger(ledger: TLedger) {
    setState((prevState) => ({
      ...prevState,
      ledger,
    }));
  }

  function setCardColor(cardColor: CardColorType) {
    setState((prevState) => ({
      ...prevState,
      cardColor,
    }));
  }

  function setCardLabel(cardLabel: string) {
    setState((prevState) => ({
      ...prevState,
      cardLabel,
    }));
  }

  function setCardInfo(cardInfo: TNewCardInfo) {
    setState((prevState) => ({
      ...prevState,
      cardInfo,
    }));
  }

  function setSecurityQuestion(securityQuestion: TCardSecurityQuestionResponse) {
    setState((prevState) => ({
      ...prevState,
      securityQuestion,
    }));
  }

  function setNameOnCard(nameOnCard: string) {
    setState((prevState) => ({
      ...prevState,
      nameOnCard,
    }));
  }

  function setDeliveryAddress(deliveryAddress: TNewPhysicalCardDeliveryAddress) {
    setState((prevState) => ({
      ...prevState,
      deliveryAddress,
    }));
  }

  function setFee(fee: TFee) {
    setState((prevState) => ({
      ...prevState,
      fee,
    }));
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value: TCardCreateContext = {
    cardCreateState: state,
    cardCreateDispatch: {
      setCardType,
      setCardOwner,
      setLedger,
      setCardLabel,
      setCardColor,
      setNameOnCard,
      setCardInfo,
      setSecurityQuestion,
      setDeliveryAddress,
      setFee,
      clear,
    },
  };

  return <cardCreateContext.Provider value={value}>{children}</cardCreateContext.Provider>;
}

export default CardCreateProvider;

export const useCardCreateContext = (): TCardCreateContext => useContext(cardCreateContext);
