/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { PropsWithChildren, ReactElement, useRef } from 'react';
import useFetch, {
  CachePolicies,
  IncomingOptions,
  Interceptors,
  Provider,
  RetryOpts,
} from 'use-http';
import * as Sentry from '@sentry/react';
import i18next from 'i18next';

import generateTraceId from 'helpers/generateTraceId';
import storage, { STORAGE_KEYS } from 'helpers/storage';
import API_ENDPOINT from 'constants/apiEndpoint';
import useLogout from 'hooks/useLogout';
import LOGIN_CHANNEL from 'constants/loginChannel';

function FetchProvider({ children }: PropsWithChildren<unknown>): ReactElement {
  const retryStarted = useRef(false);
  const logout = useLogout();
  const { post: requestRefreshToken } = useFetch(
    `${process.env.REACT_APP_BASE_API_URL}${API_ENDPOINT.REFRESH_TOKEN}`
  );

  const options: IncomingOptions = {
    interceptors: {
      request: handleRequest,
      response: handleResponse,
    } as Interceptors,
    retries: 1,
    retryOn: handleRetry,
    timeout: 45 * 1000,
    cachePolicy: CachePolicies.NETWORK_ONLY,
  };

  function handleRequest({ options: requestOptions }: { options: RequestInit }) {
    const accessToken = storage.readStorage(STORAGE_KEYS.ACCESS_TOKEN);
    if (accessToken) {
      // @ts-ignore
      requestOptions.headers.Authorization = `Bearer ${accessToken}`;
    }
    // @ts-ignore
    requestOptions.headers['X-Time-Zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // @ts-ignore
    requestOptions.headers['X-Channel'] = LOGIN_CHANNEL;
    // @ts-ignore
    requestOptions.headers['X-B3-TraceId'] = generateTraceId(16);
    // @ts-ignore
    requestOptions.headers['X-B3-SpanId'] = generateTraceId(8);

    return requestOptions;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleResponse(httpResponse: any) {
    try {
      const { response } = httpResponse;

      const xAmznTraceId = response?.headers?.get('x-amzn-trace-id');

      if (response?.status === 0) {
        response.data = {
          errorCode: '000000',
          errorMessage: 'Unexpected error try again later! Please check your internet connection!',
          xAmznTraceId,
        };
      }
      if (response?.status === 413) {
        response.data = {
          errorCode: '000000',
          errorMessage: i18next.t('label.documentMaxSizeRequest.error'),
        };
      }
      if (response?.status === 451) {
        response.data = {
          errorCode: '000000',
          errorMessage: 'Unexpected error try again later!',
        };
        Sentry.captureMessage('Restricted IP', {
          extra: {
            api: response.url,
            xAmznTraceId,
          },
        });
      }
      if (response?.status >= 500) {
        response.data = {
          errorCode: '000000',
          errorMessage: 'Unexpected error try again later!',
        };
        Sentry.captureMessage('5** API ERROR!!!', {
          extra: {
            api: response.url,
            xAmznTraceId,
          },
        });
      }

      return response;
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          location: 'handleResponse',
        },
      });
      return {
        data: {
          errorCode: '000000',
          errorMessage: 'Unexpected error try again later! Please check your internet connection!',
        },
      };
    }
  }

  async function handleRetry({ response }: RetryOpts) {
    try {
      if (!response?.ok && response?.status === 403 && !retryStarted.current) {
        retryStarted.current = true;
        const refreshToken = storage.readStorage(STORAGE_KEYS.REFRESH_TOKEN);

        const { result } = await requestRefreshToken({
          token: refreshToken,
        });

        if (result?.accessToken && result?.refreshToken) {
          storage.writeStorageFromKeys({
            [STORAGE_KEYS.ACCESS_TOKEN]: result.accessToken,
            [STORAGE_KEYS.REFRESH_TOKEN]: result.refreshToken,
          });
          retryStarted.current = false;
          return true;
        }

        logout();
        retryStarted.current = false;
        return false;
      }

      return false;
    } catch (e) {
      Sentry.captureException(e);
      logout();
      retryStarted.current = false;
      return false;
    }
  }

  return (
    <Provider url={process.env.REACT_APP_BASE_API_URL} options={options}>
      {children}
    </Provider>
  );
}

export default FetchProvider;
