import React, { useImperativeHandle, useRef, useState } from 'react';

import PageModal from 'components/PageModal';
import PasswordForm from 'components/Form/PasswordForm';
import CardViewPinModal from 'modals/CardViewPinModal';
import CardItem from 'components/v2/CardItem';
import Box from 'components/core/Box';

import { useModal } from 'components/core/Modal';
import {
  TPasswordFormData,
  TPasswordFormRef,
} from 'components/Form/PasswordForm/PasswordForm.types';
import useRetrieveSecureCardDetails from 'hooks/useRetrieveSecureCardDetails';
import { TCardViewPinModalRef } from 'modals/CardViewPinModal/CardViewPinModal.types';

import {
  TCardViewPinAuthenticationModalProps,
  TModalState,
} from './CardViewPinAuthenticationModal.types';

function CardViewPinAuthenticationModal({ modalRef }: TCardViewPinAuthenticationModalProps) {
  const ref = useModal();
  const cardViewPinModalRef = useRef<TCardViewPinModalRef>(null);
  const [state, setState] = useState<TModalState>();
  const formRef = useRef<TPasswordFormRef>(null);
  const { handleRetrieveCardInfo, loading: retrieveSecureCardDetailsLoading } =
    useRetrieveSecureCardDetails(state?.card?.id as string);

  useImperativeHandle(modalRef, () => ({
    show: (state) => {
      setState(state);
      ref.current?.show(true);
    },
  }));

  async function handleCardViewPinFlow(values: TPasswordFormData) {
    const result = await handleRetrieveCardInfo({
      password: values.password,
      onError: () => formRef.current?.clear(),
    });
    if (result?.pin && state?.card) {
      cardViewPinModalRef.current?.show({
        card: state.card,
        pin: result.pin,
        onClose: () => ref.current?.show(false),
      });
    }
    return formRef.current?.clear();
  }

  return (
    <>
      <PageModal
        modalRef={ref}
        headerTitleId="screens.headerTitle.cardViewPinAuthentication"
        sideLayoutVisible={false}
        shouldHideSideLayoutOnVisibilityChange={false}>
        {state?.card && (
          <>
            <PasswordForm
              ref={formRef}
              onSubmit={handleCardViewPinFlow}
              descriptionId="screens.cardViewPinAuthentication.description"
              loading={retrieveSecureCardDetailsLoading}>
              <Box mb="l">
                <CardItem card={state.card} />
              </Box>
            </PasswordForm>
          </>
        )}
      </PageModal>
      <CardViewPinModal modalRef={cardViewPinModalRef} />
    </>
  );
}

export default CardViewPinAuthenticationModal;
