import NOOP from 'constants/noop';

import { TSendMoneyDispatch, TSendMoneyState } from './SendMoneyProvider.types';

export const SEND_MONEY_TO_TYPES = {
  RECIPIENT: 'recipient',
  LEDGER_TO: 'ledgerTo',
};

export const INITIAL_STATE: TSendMoneyState = {
  type: undefined,
  ledgerFrom: undefined,
  schedule: undefined,
  reference: undefined,
  [SEND_MONEY_TO_TYPES.RECIPIENT]: undefined,
  [SEND_MONEY_TO_TYPES.LEDGER_TO]: undefined,
};

export const INITIAL_DISPATCH: TSendMoneyDispatch = {
  setSendMoneyType: NOOP,
  setSendMoneyLedgerFrom: NOOP,
  setSendMoneyTo: NOOP,
  setSendMoneySchedule: NOOP,
  setSendMoneyParams: NOOP,
  setSendMoneyReference: NOOP,
};
