import styled from 'styled-components';

import Text from 'components/core/Text';

export const StyledText = styled(Text)`
  overflow-wrap: break-word;
`;

export const StyledButton = styled.button`
  cursor: pointer;
  width: 100%;
`;
