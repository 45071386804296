import storage from 'helpers/storage';
import intercom from 'helpers/intercom';
import routes from 'router/routes';

function useLogout(): (route?: string) => void {
  function onLogout(route?: string) {
    storage.removePersonalData();
    intercom.clear();
    window.location.replace(route ?? routes.login.path);
  }

  return onLogout;
}

export default useLogout;
